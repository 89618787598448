<template>
  <div class="miami">
    <div class="all-area">
       <HeaderSection color="blue"/>
       <SustainabilityHero /> 
    </div>
  </div>
</template>

<script>
import HeaderSection from '../components/Header/HeaderOne';
import SustainabilityHero from '../components/Sustainability/Sustainability';


export default {
  name: 'Sustainability',
  components: {
    HeaderSection,
    SustainabilityHero
  },
  mounted() {
    this.$store.dispatch('getSustainability');
  }
}
</script>